var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Form, SegmentSelection, Button, IconButton } from 'UI';
import FilterSeries from '../FilterSeries';
import { connect } from 'react-redux';
import { edit as editMetric, save, addSeries, removeSeries, remove } from 'Duck/customMetrics';
import CustomMetricWidgetPreview from 'App/components/Dashboard/Widgets/CustomMetricsWidgets/CustomMetricWidgetPreview';
import { confirm } from 'UI/Confirmation';
import { toast } from 'react-toastify';
import cn from 'classnames';
function CustomMetricForm(props) {
    var _this = this;
    var metric = props.metric, loading = props.loading;
    var addSeries = function () {
        props.addSeries();
    };
    var removeSeries = function (index) {
        props.removeSeries(index);
    };
    var write = function (_a) {
        var _b;
        var _c = _a.target, value = _c.value, name = _c.name;
        return props.editMetric(__assign(__assign({}, metric), (_b = {}, _b[name] = value, _b)), false);
    };
    var changeConditionTab = function (e, _a) {
        var _b;
        var name = _a.name, value = _a.value;
        props.editMetric((_b = {}, _b['viewType'] = value, _b));
    };
    var save = function () {
        props.save(metric).then(function () {
            toast.success(metric.exists() ? 'Updated succesfully.' : 'Created succesfully.');
            props.onClose();
        });
    };
    var deleteHandler = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, confirm({
                        header: 'Custom Metric',
                        confirmButton: 'Delete',
                        confirmation: "Are you sure you want to delete " + metric.name
                    })];
                case 1:
                    if (_a.sent()) {
                        props.remove(metric.metricId).then(function () {
                            toast.success('Deleted succesfully.');
                            props.onClose();
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Form, { className: "relative", onSubmit: save },
        React.createElement("div", { className: "p-5 pb-20", style: { height: 'calc(100vh - 60px)', overflowY: 'auto' } },
            React.createElement("div", { className: "form-group" },
                React.createElement("label", { className: "font-medium" }, "Metric Title"),
                React.createElement("input", { autoFocus: true, className: "text-lg", name: "name", style: { fontSize: '18px', padding: '10px', fontWeight: '600' }, value: metric.name, onChange: write, placeholder: "Metric Title", id: "name-field" })),
            React.createElement("div", { className: "form-group" },
                React.createElement("label", { className: "font-medium" }, "Metric Type"),
                React.createElement("div", { className: "flex items-center" },
                    React.createElement("span", { className: "bg-white p-1 px-2 border rounded", style: { height: '30px' } }, "Timeseries"),
                    React.createElement("span", { className: "mx-2 color-gray-medium" }, "of"),
                    React.createElement("div", null,
                        React.createElement(SegmentSelection, { primary: true, name: "viewType", small: true, 
                            // className="my-3"
                            onSelect: changeConditionTab, value: { value: metric.viewType }, list: [
                                { name: 'Session Count', value: 'lineChart' },
                                { name: 'Session Percentage', value: 'progress', disabled: true },
                            ] })))),
            React.createElement("div", { className: "form-group" },
                React.createElement("label", { className: "font-medium" }, "Chart Series"),
                metric.series && metric.series.size > 0 && metric.series.map(function (series, index) { return (React.createElement("div", { className: "mb-2" },
                    React.createElement(FilterSeries, { seriesIndex: index, series: series, onRemoveSeries: function () { return removeSeries(index); }, canDelete: metric.series.size > 1 }))); })),
            React.createElement("div", { className: cn("flex justify-end -my-4", { 'disabled': metric.series.size > 2 }) },
                React.createElement(IconButton, { hover: true, type: "button", onClick: addSeries, primaryText: true, label: "SERIES", icon: "plus" })),
            React.createElement("div", { className: "my-8" }),
            React.createElement(CustomMetricWidgetPreview, { metric: metric })),
        React.createElement("div", { className: "flex items-center fixed border-t w-full bottom-0 px-5 py-2 bg-white" },
            React.createElement("div", { className: "mr-auto" },
                React.createElement(Button, { loading: loading, primary: true, disabled: !metric.validate() }, "" + (metric.exists() ? 'Update' : 'Create')),
                React.createElement(Button, { type: "button", className: "ml-3", outline: true, hover: true, plain: true, onClick: props.onClose }, "Cancel")),
            React.createElement("div", null, metric.exists() && React.createElement(Button, { type: "button", className: "ml-3", outline: true, hover: true, plain: true, onClick: deleteHandler }, "Delete")))));
}
export default connect(function (state) { return ({
    metric: state.getIn(['customMetrics', 'instance']),
    loading: state.getIn(['customMetrics', 'saveRequest', 'loading']),
}); }, { editMetric: editMetric, save: save, addSeries: addSeries, remove: remove, removeSeries: removeSeries })(CustomMetricForm);
