var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import FilterAutoComplete from '../FilterAutoComplete';
import FilterAutoCompleteLocal from '../FilterAutoCompleteLocal';
import { FilterKey, FilterCategory, FilterType } from 'Types/filter/filterType';
import FilterValueDropdown from '../FilterValueDropdown';
import FilterDuration from '../FilterDuration';
import { debounce } from 'App/utils';
function FilterValue(props) {
    var filter = props.filter;
    var _a = __read(useState({ minDuration: filter.value[0], maxDuration: filter.value[1] }), 2), durationValues = _a[0], setDurationValues = _a[1];
    var showCloseButton = filter.value.length > 1;
    var lastIndex = filter.value.length - 1;
    var onAddValue = function () {
        var newValue = filter.value.concat('');
        props.onUpdate(__assign(__assign({}, filter), { value: newValue }));
    };
    var onRemoveValue = function (valueIndex) {
        var newValue = filter.value.filter(function (_, index) { return index !== valueIndex; });
        props.onUpdate(__assign(__assign({}, filter), { value: newValue }));
    };
    var onChange = function (e, item, valueIndex) {
        var newValues = filter.value.map(function (_, _index) {
            if (_index === valueIndex) {
                return item.value;
            }
            return _;
        });
        props.onUpdate(__assign(__assign({}, filter), { value: newValues }));
    };
    var debounceOnSelect = React.useCallback(debounce(onChange, 500), [onChange]);
    var onDurationChange = function (newValues) {
        setDurationValues(__assign(__assign({}, durationValues), newValues));
    };
    var handleBlur = function (e) {
        if (filter.type === FilterType.DURATION) {
            var maxDuration = filter.maxDuration, minDuration = filter.minDuration, key = filter.key;
            if (maxDuration || minDuration)
                return;
            if (maxDuration !== durationValues.maxDuration ||
                minDuration !== durationValues.minDuration) {
                props.onUpdate(__assign(__assign({}, filter), { value: [durationValues.minDuration, durationValues.maxDuration] }));
            }
        }
    };
    var getParms = function (key) {
        switch (filter.category) {
            case FilterCategory.METADATA:
                return { type: FilterKey.METADATA, key: key };
            default:
                return { type: filter.key };
        }
    };
    var renderValueFiled = function (value, valueIndex) {
        var showOrButton = valueIndex === lastIndex;
        switch (filter.type) {
            case FilterType.STRING:
                return (React.createElement(FilterAutoCompleteLocal, { value: value, showCloseButton: showCloseButton, showOrButton: showOrButton, onAddValue: onAddValue, onRemoveValue: function () { return onRemoveValue(valueIndex); }, onSelect: function (e, item) { return debounceOnSelect(e, item, valueIndex); }, icon: filter.icon }));
            case FilterType.DROPDOWN:
                return (React.createElement(FilterValueDropdown, { search: true, value: value, filter: filter, options: filter.options, onChange: function (e, _a) {
                        var name = _a.name, value = _a.value;
                        return onChange(e, { value: value }, valueIndex);
                    } }));
            case FilterType.ISSUE:
            case FilterType.MULTIPLE_DROPDOWN:
                return (React.createElement(FilterValueDropdown, { search: true, multiple: true, value: value, filter: filter, options: filter.options, onChange: function (e, _a) {
                        var name = _a.name, value = _a.value;
                        return onChange(e, { value: value }, valueIndex);
                    }, onAddValue: onAddValue, onRemoveValue: function () { return onRemoveValue(valueIndex); }, showCloseButton: showCloseButton, showOrButton: showOrButton }));
            case FilterType.DURATION:
                return (React.createElement(FilterDuration, { onChange: onDurationChange, 
                    // onEnterPress={ this.handleClose }
                    onBlur: handleBlur, minDuration: durationValues.minDuration, maxDuration: durationValues.maxDuration }));
            case FilterType.NUMBER:
                return (React.createElement("input", { className: "w-full px-2 py-1 text-sm leading-tight text-gray-700 rounded-lg", type: "number", name: filter.key + "-" + valueIndex, value: value, onChange: function (e) { return onChange(e, { value: e.target.value }, valueIndex); } }));
            case FilterType.MULTIPLE:
                return (React.createElement(FilterAutoComplete, { value: value, showCloseButton: showCloseButton, showOrButton: showOrButton, onAddValue: onAddValue, onRemoveValue: function () { return onRemoveValue(valueIndex); }, method: 'GET', endpoint: '/events/search', params: getParms(filter.key), headerText: '', 
                    // placeholder={''}
                    onSelect: function (e, item) { return onChange(e, item, valueIndex); }, icon: filter.icon }));
        }
    };
    return (React.createElement("div", { className: "grid grid-cols-3 gap-3 w-full" }, filter.type === FilterType.DURATION ? (renderValueFiled(filter.value, 0)) : (filter.value && filter.value.map(function (value, valueIndex) { return (React.createElement("div", { key: valueIndex }, renderValueFiled(value, valueIndex))); }))));
}
export default FilterValue;
