var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchList } from 'Duck/customMetrics';
import CustomMetricWidget from './CustomMetricWidget';
import AlertFormModal from 'App/components/Alerts/AlertFormModal';
import { init as initAlert } from 'Duck/alerts';
import LazyLoad from 'react-lazyload';
function CustomMetricsWidgets(props) {
    var list = props.list;
    var _a = __read(useState(null), 2), activeMetricId = _a[0], setActiveMetricId = _a[1];
    useEffect(function () {
        props.fetchList();
    }, []);
    return (React.createElement(React.Fragment, null,
        list.filter(function (item) { return item.active; }).map(function (item) { return (React.createElement(LazyLoad, null,
            React.createElement(CustomMetricWidget, { key: item.metricId, metric: item, onClickEdit: props.onClickEdit, onAlertClick: function (e) {
                    setActiveMetricId(item.metricId);
                    props.initAlert({ query: { left: item.series.first().seriesId } });
                } }))); }),
        React.createElement(AlertFormModal, { showModal: !!activeMetricId, metricId: activeMetricId, onClose: function () { return setActiveMetricId(null); } })));
}
export default connect(function (state) { return ({
    list: state.getIn(['customMetrics', 'list']),
}); }, { fetchList: fetchList, initAlert: initAlert })(CustomMetricsWidgets);
