export var FilterCategory;
(function (FilterCategory) {
    FilterCategory["INTERACTIONS"] = "Interactions";
    FilterCategory["GEAR"] = "Gear";
    FilterCategory["RECORDING_ATTRIBUTES"] = "Recording Attributes";
    FilterCategory["JAVASCRIPT"] = "Javascript";
    FilterCategory["USER"] = "User";
    FilterCategory["METADATA"] = "Metadata";
    FilterCategory["PERFORMANCE"] = "Performance";
})(FilterCategory || (FilterCategory = {}));
;
export var FilterType;
(function (FilterType) {
    FilterType["STRING"] = "STRING";
    FilterType["ISSUE"] = "ISSUE";
    FilterType["BOOLEAN"] = "BOOLEAN";
    FilterType["NUMBER"] = "NUMBER";
    FilterType["DURATION"] = "DURATION";
    FilterType["MULTIPLE"] = "MULTIPLE";
    FilterType["COUNTRY"] = "COUNTRY";
    FilterType["DROPDOWN"] = "DROPDOWN";
    FilterType["MULTIPLE_DROPDOWN"] = "MULTIPLE_DROPDOWN";
    FilterType["AUTOCOMPLETE_LOCAL"] = "AUTOCOMPLETE_LOCAL";
})(FilterType || (FilterType = {}));
;
export var FilterKey;
(function (FilterKey) {
    FilterKey["ERROR"] = "ERROR";
    FilterKey["MISSING_RESOURCE"] = "MISSING_RESOURCE";
    FilterKey["SLOW_SESSION"] = "SLOW_SESSION";
    FilterKey["CLICK_RAGE"] = "CLICK_RAGE";
    FilterKey["CLICK"] = "CLICK";
    FilterKey["INPUT"] = "INPUT";
    FilterKey["LOCATION"] = "LOCATION";
    FilterKey["VIEW"] = "VIEW";
    FilterKey["CONSOLE"] = "CONSOLE";
    FilterKey["METADATA"] = "METADATA";
    FilterKey["CUSTOM"] = "CUSTOM";
    FilterKey["URL"] = "URL";
    FilterKey["USER_BROWSER"] = "USERBROWSER";
    FilterKey["USER_OS"] = "USEROS";
    FilterKey["USER_DEVICE"] = "USERDEVICE";
    FilterKey["PLATFORM"] = "PLATFORM";
    FilterKey["DURATION"] = "DURATION";
    FilterKey["REFERRER"] = "REFERRER";
    FilterKey["USER_COUNTRY"] = "USERCOUNTRY";
    FilterKey["JOURNEY"] = "JOURNEY";
    FilterKey["REQUEST"] = "REQUEST";
    FilterKey["GRAPHQL"] = "GRAPHQL";
    FilterKey["STATEACTION"] = "STATEACTION";
    FilterKey["REVID"] = "REVID";
    FilterKey["USERANONYMOUSID"] = "USERANONYMOUSID";
    FilterKey["USERID"] = "USERID";
    FilterKey["ISSUE"] = "ISSUE";
    FilterKey["EVENTS_COUNT"] = "EVENTS_COUNT";
    FilterKey["UTM_SOURCE"] = "UTM_SOURCE";
    FilterKey["UTM_MEDIUM"] = "UTM_MEDIUM";
    FilterKey["UTM_CAMPAIGN"] = "UTM_CAMPAIGN";
    FilterKey["DOM_COMPLETE"] = "DOM_COMPLETE";
    FilterKey["LARGEST_CONTENTFUL_PAINT_TIME"] = "LARGEST_CONTENTFUL_PAINT_TIME";
    FilterKey["TIME_BETWEEN_EVENTS"] = "TIME_BETWEEN_EVENTS";
    FilterKey["TTFB"] = "TTFB";
    FilterKey["AVG_CPU_LOAD"] = "AVG_CPU_LOAD";
    FilterKey["AVG_MEMORY_USAGE"] = "AVG_MEMORY_USAGE";
    FilterKey["FETCH_FAILED"] = "FETCH_FAILED";
})(FilterKey || (FilterKey = {}));
