var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader, NoContent, Icon, Popup } from 'UI';
import { Styles } from '../../common';
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { LineChart, Line, Legend } from 'recharts';
import { LAST_24_HOURS, LAST_30_MINUTES, YESTERDAY, LAST_7_DAYS } from 'Types/app/period';
import stl from './CustomMetricWidget.css';
import { getChartFormatter, getStartAndEndTimestampsByDensity } from 'Types/dashboard/helper';
import { init, edit, remove, setAlertMetricId, setActiveWidget, updateActiveState } from 'Duck/customMetrics';
import APIClient from 'App/api_client';
import { setShowAlerts } from 'Duck/dashboard';
var customParams = function (rangeName) {
    var params = { density: 70 };
    if (rangeName === LAST_24_HOURS)
        params.density = 70;
    if (rangeName === LAST_30_MINUTES)
        params.density = 70;
    if (rangeName === YESTERDAY)
        params.density = 70;
    if (rangeName === LAST_7_DAYS)
        params.density = 70;
    return params;
};
function CustomMetricWidget(props) {
    var metric = props.metric, showSync = props.showSync, compare = props.compare, period = props.period;
    var _a = __read(useState(false), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read(useState([]), 2), data = _b[0], setData = _b[1];
    var _c = __read(useState([]), 2), seriesMap = _c[0], setSeriesMap = _c[1];
    var colors = Styles.customMetricColors;
    var params = customParams(period.rangeName);
    var gradientDef = Styles.gradientDef();
    var metricParams = __assign(__assign({}, params), { metricId: metric.metricId, viewType: 'lineChart', startDate: period.start, endDate: period.end });
    useEffect(function () {
        new APIClient()['post']('/custom_metrics/chart', __assign(__assign({}, metricParams), { q: metric.name }))
            .then(function (response) { return response.json(); })
            .then(function (_a) {
            var errors = _a.errors, data = _a.data;
            if (errors) {
                console.log('err', errors);
            }
            else {
                var namesMap = data
                    .map(function (i) { return Object.keys(i); })
                    .flat()
                    .filter(function (i) { return i !== 'time' && i !== 'timestamp'; })
                    .reduce(function (unique, item) {
                    if (!unique.includes(item)) {
                        unique.push(item);
                    }
                    return unique;
                }, []);
                setSeriesMap(namesMap);
                setData(getChartFormatter(period)(data));
            }
        }).finally(function () { return setLoading(false); });
    }, [period]);
    var clickHandler = function (event, index) {
        if (event) {
            var payload = event.activePayload[0].payload;
            var timestamp = payload.timestamp;
            var _a = getStartAndEndTimestampsByDensity(timestamp, period.start, period.end, params.density), startTimestamp = _a.startTimestamp, endTimestamp = _a.endTimestamp;
            props.setActiveWidget({ widget: metric, startTimestamp: startTimestamp, endTimestamp: endTimestamp, timestamp: payload.timestamp, index: index });
        }
    };
    var updateActiveState = function (metricId, state) {
        props.updateActiveState(metricId, state);
    };
    return (React.createElement("div", { className: stl.wrapper },
        React.createElement("div", { className: "flex items-center mb-10 p-2" },
            React.createElement("div", { className: "font-medium" }, metric.name),
            React.createElement("div", { className: "ml-auto flex items-center" },
                React.createElement(WidgetIcon, { className: "cursor-pointer mr-6", icon: "bell-plus", tooltip: "Set Alert", onClick: props.onAlertClick }),
                React.createElement(WidgetIcon, { className: "cursor-pointer mr-6", icon: "pencil", tooltip: "Edit Metric", onClick: function () { return props.init(metric); } }),
                React.createElement(WidgetIcon, { className: "cursor-pointer", icon: "close", tooltip: "Hide Metric", onClick: function () { return updateActiveState(metric.metricId, false); } }))),
        React.createElement("div", null,
            React.createElement(Loader, { loading: loading, size: "small" },
                React.createElement(NoContent, { size: "small", show: data.length === 0 },
                    React.createElement(ResponsiveContainer, { height: 240, width: "100%" },
                        React.createElement(LineChart, { data: data, margin: Styles.chartMargins, syncId: showSync ? "domainsErrors_4xx" : undefined, onClick: clickHandler },
                            React.createElement("defs", null,
                                React.createElement("linearGradient", { id: "colorCount", x1: "0", y1: "0", x2: "0", y2: "1" },
                                    React.createElement("stop", { offset: "5%", stopColor: colors[4], stopOpacity: 0.9 }),
                                    React.createElement("stop", { offset: "95%", stopColor: colors[4], stopOpacity: 0.2 }))),
                            React.createElement(CartesianGrid, { strokeDasharray: "3 3", vertical: false, stroke: "#EEEEEE" }),
                            React.createElement(XAxis, __assign({}, Styles.xaxis, { dataKey: "time", interval: params.density / 7 })),
                            React.createElement(YAxis, __assign({}, Styles.yaxis, { allowDecimals: false, label: __assign(__assign({}, Styles.axisLabelLeft), { value: "Number of Sessions" }) })),
                            React.createElement(Legend, null),
                            React.createElement(Tooltip, __assign({}, Styles.tooltip)),
                            seriesMap.map(function (key, index) { return (React.createElement(Line, { key: key, name: key, type: "monotone", dataKey: key, stroke: colors[index], fillOpacity: 1, strokeWidth: 2, strokeOpacity: 0.8, fill: "url(#colorCount)", dot: false })); }))))))));
}
export default connect(function (state) { return ({
    period: state.getIn(['dashboard', 'period']),
}); }, {
    remove: remove,
    setShowAlerts: setShowAlerts,
    setAlertMetricId: setAlertMetricId,
    edit: edit,
    setActiveWidget: setActiveWidget,
    updateActiveState: updateActiveState,
    init: init,
})(CustomMetricWidget);
var WidgetIcon = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.tooltip, tooltip = _c === void 0 ? '' : _c, icon = _a.icon, onClick = _a.onClick;
    return (React.createElement(Popup, { size: "small", trigger: React.createElement("div", { className: className, onClick: onClick },
            React.createElement(Icon, { name: icon, size: "14" })), content: tooltip, position: "top center", inverted: true }));
};
