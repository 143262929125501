import React from 'react';
import SessionSearchField from 'Shared/SessionSearchField';
import SavedSearch from 'Shared/SavedSearch';
import { Button, Popup } from 'UI';
import { clearSearch } from 'Duck/search';
import { connect } from 'react-redux';
var MainSearchBar = function (props) {
    var appliedFilter = props.appliedFilter;
    var hasFilters = appliedFilter && appliedFilter.filters && appliedFilter.filters.size > 0;
    return (React.createElement("div", { className: "flex items-center" },
        React.createElement("div", { style: { width: "60%", marginRight: "10px" } },
            React.createElement(SessionSearchField, null)),
        React.createElement("div", { className: "flex items-center", style: { width: "40%" } },
            React.createElement(SavedSearch, null),
            React.createElement(Popup, { trigger: React.createElement(Button, { plain: true, disabled: !hasFilters, className: "ml-auto", onClick: function () { return props.clearSearch(); } },
                    React.createElement("span", { className: "font-medium" }, "Clear")), content: 'Clear Steps', size: "tiny", inverted: true, position: "top right" }))));
};
export default connect(function (state) { return ({
    appliedFilter: state.getIn(['search', 'instance']),
}); }, { clearSearch: clearSearch })(MainSearchBar);
