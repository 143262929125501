import React from 'react';
import stl from './DropdownPlain.css';
import { Dropdown, Icon } from 'UI';
export default function DropdownPlain(props) {
    var value = props.value, options = props.options, _a = props.icon, icon = _a === void 0 ? "chevron-down" : _a, _b = props.direction, direction = _b === void 0 ? "left" : _b;
    return (React.createElement("div", null,
        React.createElement(Dropdown, { value: value, name: "sort", className: stl.dropdown, direction: direction, options: options, onChange: props.onChange, scrolling: true, 
            // defaultValue={ value }
            icon: icon ? React.createElement(Icon, { name: "chevron-down", color: "gray-dark", size: "14", className: stl.dropdownIcon }) : null })));
}
