var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import FilterList from 'Shared/Filters/FilterList';
import { edit, updateSeries, addSeriesFilterFilter, removeSeriesFilterFilter, editSeriesFilterFilter, editSeriesFilter, } from 'Duck/customMetrics';
import { connect } from 'react-redux';
import { IconButton, Icon } from 'UI';
import FilterSelection from '../../Filters/FilterSelection';
import SeriesName from './SeriesName';
import cn from 'classnames';
function FilterSeries(props) {
    var canDelete = props.canDelete;
    var _a = __read(useState(true), 2), expanded = _a[0], setExpanded = _a[1];
    var series = props.series, seriesIndex = props.seriesIndex;
    var onAddFilter = function (filter) {
        filter.value = [""];
        props.addSeriesFilterFilter(seriesIndex, filter);
    };
    var onUpdateFilter = function (filterIndex, filter) {
        props.editSeriesFilterFilter(seriesIndex, filterIndex, filter);
    };
    var onChangeEventsOrder = function (e, _a) {
        var name = _a.name, value = _a.value;
        props.editSeriesFilter(seriesIndex, { eventsOrder: value });
    };
    var onRemoveFilter = function (filterIndex) {
        props.removeSeriesFilterFilter(seriesIndex, filterIndex);
    };
    return (React.createElement("div", { className: "border rounded bg-white" },
        React.createElement("div", { className: "border-b px-5 h-12 flex items-center relative" },
            React.createElement("div", { className: "mr-auto" },
                React.createElement(SeriesName, { name: series.name, onUpdate: function (name) { return props.updateSeries(seriesIndex, { name: name }); } })),
            React.createElement("div", { className: "flex items-center cursor-pointer" },
                React.createElement("div", { onClick: props.onRemoveSeries, className: cn("ml-3", { 'disabled': !canDelete }) },
                    React.createElement(Icon, { name: "trash", size: "16" })),
                React.createElement("div", { onClick: function () { return setExpanded(!expanded); }, className: "ml-3" },
                    React.createElement(Icon, { name: "chevron-down", size: "16" })))),
        expanded && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "p-5" }, series.filter.filters.size > 0 ? (React.createElement(FilterList, { filter: series.filter, onUpdateFilter: onUpdateFilter, onRemoveFilter: onRemoveFilter, onChangeEventsOrder: onChangeEventsOrder })) : (React.createElement("div", { className: "color-gray-medium" }, "Add user event or filter to define the series by clicking Add Step."))),
            React.createElement("div", { className: "px-5 border-t h-12 flex items-center" },
                React.createElement(FilterSelection, { filter: undefined, onFilterClick: onAddFilter },
                    React.createElement(IconButton, { primaryText: true, label: "ADD STEP", icon: "plus" })))))));
}
export default connect(null, {
    edit: edit,
    updateSeries: updateSeries,
    addSeriesFilterFilter: addSeriesFilterFilter,
    editSeriesFilterFilter: editSeriesFilterFilter,
    editSeriesFilter: editSeriesFilter,
    removeSeriesFilterFilter: removeSeriesFilterFilter,
})(FilterSeries);
